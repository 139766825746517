import { create } from 'zustand';
import { StatsStore } from '@types';

export const useStats = create<StatsStore>(set => ({
    stats: {
        size: 0,
        allToursIndex: 0,
        finished: false,
    },
    updateStat: (key, value) => set(state => ({
        stats: { ...state.stats, [key]: value }
    })),
    updateStats: (newStats) => set(state => ({
        stats: { ...state.stats, ...newStats }
    })),
    resetStats: () => set(() => ({
        stats: {
            size: 0,
            allToursIndex: 0,
            finished: false,
        }
    }))
}));
