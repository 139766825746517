import { PolarPair } from '@types';
import { Cartesian3, Cartographic, Math as CesiumMath } from 'cesium';

interface PointObj {
    cartesian3?: Cartesian3;
    degrees?: PolarPair;
    radians?: PolarPair;
}

export class Point {
    cartesian3: Cartesian3;
    degrees: PolarPair;
    radians: PolarPair;

    constructor(arg: Cartesian3 | PointObj) {
        if (arg instanceof Cartesian3) {
            this.cartesian3 = arg;
            const cartographic = Cartographic.fromCartesian(this.cartesian3);

            this.degrees = {
                latitude: CesiumMath.toDegrees(cartographic.latitude),
                longitude: CesiumMath.toDegrees(cartographic.longitude)
            };

            this.radians = {
                latitude: cartographic.latitude,
                longitude: cartographic.longitude
            };
        } else {
            if (!arg.cartesian3 || !arg.degrees || !arg.radians) {
                throw new Error("All properties must be provided when using the PointObj constructor.");
            }
            this.cartesian3 = arg.cartesian3;
            this.degrees = arg.degrees;
            this.radians = arg.radians;
        }
    }
}
