import { create } from 'zustand';
import { useSnackbar } from 'notistack';
import { AppStore, DemoInfo } from '@types';

const useZustandStore = create<AppStore>((set) => ({
    showOptimize: false,
    setShowOptimize: show => set({ showOptimize: show }),
    showFinished: false,
    setShowFinished: show => set({ showFinished: show }),
    demos: [],
    setDemos: (value: DemoInfo[]) => set({ demos: value }),
    loadedDemos: false,
    setLoadedDemos: (value: boolean) => set({ loadedDemos: value }),
}));

export function useApp() {
    const { enqueueSnackbar } = useSnackbar();

    const showMessage = (message: string, variant: "error" | "success" | "warning" | "info" = "info") => {
        enqueueSnackbar(message, { variant });
    };

    const { showOptimize, setShowOptimize, showFinished, setShowFinished, demos, setDemos, loadedDemos, setLoadedDemos } = useZustandStore();

    return {
        showOptimize,
        setShowOptimize,
        showFinished,
        setShowFinished,
        demos,
        setDemos,
        loadedDemos,
        setLoadedDemos,
        showMessage
    };
}
