import React, { useEffect } from "react";
import { useApp, usePlaces } from '@state';
import OptimizeButton from '@components/OptimizeButton.tsx';
import Menu from '@components/Menu/Menu.tsx';
import Settings from '@components/Menu/Settings.tsx';
import LoadFile from '@components/Menu/LoadFile.tsx';
import Demo from '@components/Menu/Demo.tsx';
import Itinerary from '@components/Menu/Itinerary.tsx';
import Stats from '@components/Menu/Stats.tsx';
import CesiumController from '@components/CesiumController.tsx';


const Page = () => {
    const { showOptimize, setShowOptimize } = useApp();
    const { places } = usePlaces();

    useEffect(() => {
        if (places.length > 2 && !showOptimize) {
            setShowOptimize(true);
        } else if (places.length <= 2 && showOptimize) {
            setShowOptimize(false);
        }
    }, [places, showOptimize]);

    const Header = () => {

        const Main = () => (
            <div className="half-width vertical-center">
                <div className="header-container">
                    <Menu/>
                    <h1 className="tco-text-upper header-title" data-testid="header-title">
                        <span className={'header-title-delimiter'}>|</span>{APP_NAME}
                    </h1>
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <Main/>
                <Settings/>
                <LoadFile/>
                <Demo/>
                <Itinerary/>
                <Stats/>
            </React.Fragment>
        );
    };

    const Footer = () => (
        <div className={'copyright'}>
            <h6>Cooper Larson © {new Date().getFullYear()}</h6>
        </div>
    );

    return (
        <div className="full-page-app">
            <Header/>
            <CesiumController />
            <OptimizeButton className={'primary-optimize-button'} />
            <Footer/>
        </div>
    );
}


export default Page;
