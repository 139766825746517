import React from "react";
import { Button, ButtonGroup, Collapse } from "reactstrap";
import { FaTrash } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";
import { PlaceAddlInfoProps, PlaceProps } from "@types";
import { useToggle } from "@hooks";


const PlaceRow: React.FC<PlaceProps> = (props) => {
    const { place, index, placeActions, distances } = props;
    const [showFullName, toggleShowFullName] = useToggle(false);
    const name = place.defaultDisplayName;
    const location = place.coordinatesToString();
    const additionalProps = { place, index, showFullName, location };

    const PlaceActions = () => (
        <ButtonGroup size="sm">
            <Button
                color="primary"
                onClick={() => placeActions.removeAtIndex(index)}
                data-testid="remove-place-button"
            >
                <FaTrash/>
            </Button>
        </ButtonGroup>
    );

    const AdditionalPlaceInfo: React.FC<PlaceAddlInfoProps> = () => (
        <Collapse isOpen={showFullName}>
            {`${place.defaultDisplayName}, `.replace(`${place.defaultDisplayName}, `, '')}
            <br/>
            {location}
            <br/>
            <PlaceActions/>
        </Collapse>
    );

    return (
        <tr className={props.selectedIndex === index ? 'selected-row place-row' : 'place-row'}>
            <td
                data-testid={`place-row-${index}`}
                onClick={() => placeRowClicked(toggleShowFullName, placeActions.selectIndex, index)}
            >
                <strong>{name}</strong>
                <AdditionalPlaceInfo {...additionalProps} />
            </td>
            <td className="text-end">{distances.leg[index]}</td>
            <td className="text-end">{distances.cumulative[index]}</td>
            <RowArrow toggleShowFullName={toggleShowFullName} index={index}/>
        </tr>
    );
};

function placeRowClicked(toggleShowFullName: () => void, selectIndex: (index: number) => void, placeIndex: number) {
    toggleShowFullName();
    selectIndex(placeIndex);
}

const RowArrow: React.FC<{ toggleShowFullName: () => void, index: number }> = ({ toggleShowFullName, index }) => (
    <td>
        <BsChevronDown data-testid={`place-row-toggle-${index}`} onClick={toggleShowFullName}/>
    </td>
);


export default PlaceRow;
