import { useState } from 'react';

export function useToggle(starting: boolean): [toggled: boolean, toggle: () => void] {
    const [toggled, setToggled] = useState(starting);

    function toggle() {
        setToggled(!toggled);
    }

    return [toggled, toggle];
}