import { FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { FormProvider, useFormContext } from "react-hook-form";
import useInputController from "@utils/useInputController.tsx";
import { SettingsInputProps } from "@types";
import React from "react";
import { InputType } from 'reactstrap/types/lib/Input';

const FormInput: React.FC<SettingsInputProps> = ({ config, children, ...rest }) => {
    const { label, role, name, type } = config;
    const formController = useFormContext();
    const inputController = useInputController(config);

    const { fieldState: { error }, field } = inputController;

    const className = {
        formGroup: role ? 'form-check form-switch settings-form-group' : `settings-form-group ${type}`,
        label: role ? 'form-check-label' : ''
    }

    const InputLabel = () => <Label htmlFor={name} className={className.label}>{label}:</Label>;

    const InputAndFeedback = () => (
        <>
            <InputGenerator />
            {error && <FormFeedback>{error.message}</FormFeedback>}
        </>
    )

    const RenderInput = () => {
        return type === 'checkbox' ? (
            <Row>
                <InputLabel/>
                <InputAndFeedback/>
            </Row>
        ) : (
            <>
                <Row><InputLabel/></Row>
                <Row><InputAndFeedback/></Row>
            </>
        )
    }

    const InputGenerator = () => {
        const { type } = field;
        const inputType = isValidInputType(type) ? type : 'text';

        switch (inputType) {
            case 'search':
                return (
                    <>
                        <Input {...field} {...rest} type={inputType} />
                        {children}
                    </>
                );
            case 'select':
                return (
                    <Input {...field} {...rest} type={inputType}>
                        {children}
                    </Input>
                );
            default:
                return <Input {...field} {...rest} type={inputType} />
        }
    }

    function isValidInputType(type: string): type is InputType {
        return ['text', 'email', 'checkbox', 'select', 'button', 'number', 'radio'].includes(type);
    }


    return (
        <FormProvider {...formController} {...inputController}>
            <FormGroup id={name} floating={!role} className={className.formGroup}>
                <RenderInput/>
            </FormGroup>
        </FormProvider>
    )
}


export default FormInput;
