import { Button } from "reactstrap";
import React from "react";
import { Place } from "@models/place.model.tsx";
import { IResponse, PlaceObj, TourResponse } from "@types";
import { useConfig, usePlaces, useStats, useWebSocket } from '@state';

const OptimizeButton: React.FC<{ className: string }> = ({ className }) => {
    const { places, setPlaces } = usePlaces();
    const { stats, updateStats } = useStats();
    const { setAnimated, settings: { tourId }, setTourId } = useConfig();
    const { sendData, generateId } = useWebSocket();

    const isTourResponse = (response: IResponse) : response is TourResponse => {
        return response["type"] === "tour" && 'places' in response && 'earthRadius' in response && 'response' in response;
    }

    const optimizeTrip = async () => {
        if (!tourId) setTourId(generateId());
        sendData({ type: "tour", earthRadius: 3960, response: 1, places }, (response: IResponse) => {
            if (isTourResponse(response)) {
                setAnimated(true);
                setPlaces(response.places.map((obj: PlaceObj) => new Place(obj)));
                updateStats(response.stats);
            }
        }, tourId as string);
    };

    const classname = `optimize-button ${className}`;

    return (
        <>
            {stats && stats.finished && <div className={'optimized-message'}><h1>Tour is fully optimized</h1></div>}
            <Button color="primary" className={classname} onClick={() => optimizeTrip()} size="sm">Optimize</Button>
        </>
    )
}

export default OptimizeButton;
