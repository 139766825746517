import { Card, CardBody, CardFooter, CardHeader, CardTitle, Form } from 'reactstrap';
import { DistanceUnit } from "@types";
import { FormProvider, useForm } from "react-hook-form";
import { animateConfig, distanceUnits, distanceUnitsConfig, responseConfig, showBallTreeConfig } from "@utils/settingsConfig.ts";
import FormInput from "@utils/FormInput.tsx";
import { useConfig, useMenu } from '@state';

const Settings = () => {
    const { activeMenuItem } = useMenu();
    const formController = useForm();

    const { settings: {distanceUnit, response, animate, showBallTree} } = useConfig();

    const units: DistanceUnit[] = Object.values(distanceUnits);

    const DistanceUnitsOptions = () => {
        return units.map(unit => (
            <option key={unit.name} value={unit.name}>{unit.name}</option>
        ));
    }

    const Inputs = () => (
        <div>
            <FormInput config={animateConfig} checked={animate}/>
            <FormInput config={showBallTreeConfig} checked={showBallTree} />
            <FormInput config={responseConfig} value={response}/>
            <FormInput config={distanceUnitsConfig} value={distanceUnit.name}><DistanceUnitsOptions/></FormInput>
        </div>
    )

    return activeMenuItem === 'settings' && (
        <Card>
            <CardHeader>
                <CardTitle>Settings</CardTitle>
            </CardHeader>
            <CardBody>
                <FormProvider {...formController} >
                    <Form name="settings-form" id="settings-form">
                        <Inputs/>
                    </Form>
                </FormProvider>
            </CardBody>
            <CardFooter>
            </CardFooter>
        </Card>
    );
};

export default Settings;
