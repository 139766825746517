import { create } from 'zustand';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { IRequest, IResponse, WebSocketStore } from '@types';  // Assumed path, adjust accordingly

const WS_URL = WEB_SOCKET_URI;

export const useWebSocket = create<WebSocketStore>(() => {
    const callbacks = new Map<string, (response: IResponse) => void>();

    const socket = new ReconnectingWebSocket(WS_URL);
    socket.addEventListener('open', () => console.log('Connected to WS Server'));

    socket.addEventListener('message', (event: MessageEvent) => {
        const response: IResponse = JSON.parse(event.data);
        if (response.id && callbacks.has(response.id)) {
            const callback = callbacks.get(response.id);
            if (callback) {
                callback(response);
                callbacks.delete(response.id);
            }
        }
    });

    socket.addEventListener('error', (error) => {
        console.error('WebSocket Error:', error);
    });

    socket.addEventListener('close', () => console.log('Disconnected from WS Server'));

    const generateId = () : string => Math.random().toString(36).substring(2, 15);

    const sendData = (request: IRequest, callback?: (response: IResponse) => void, id?: string) => {
        const finalId = id || generateId();
        if (callback) callbacks.set(finalId, callback);
        socket.send(JSON.stringify({ ...request, id: finalId }));
    };

    return {
        sendData,
        generateId,
        closeConnection: () => socket.close()
    };
});
