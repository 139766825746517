import { useConfig, usePlaces, useViewer } from '@state';
import { ScreenSpaceEventHandler, ScreenSpaceEventType } from 'cesium';
import { ClickEventProps } from '@types';

export const useEventListeners = () => {
    const viewer = useViewer.getState().viewer;
    const { append } = usePlaces();
    const { setAnimated, clearTourId } = useConfig();

    const setupEventListeners = () => {

        if (!viewer) return;

        const handler = new ScreenSpaceEventHandler(viewer.canvas);

        const handleLeftClick = (event: ClickEventProps) => {
            try {
                if (viewer.scene.globe.tilesLoaded) {
                    const cartesian = viewer.scene.pickPosition(event.position);
                    if (cartesian) {
                        setAnimated(false);
                        append(cartesian);
                        clearTourId();
                    }
                }
            } catch (error) {
                console.error('Error handling left click event:', error);
            }
        };

        handler.setInputAction(handleLeftClick, ScreenSpaceEventType.LEFT_CLICK);

        // Clean-up function to be called when viewer is unmounted or event listeners need to be refreshed
        return () => {
            handler.destroy();
        };
    }

    return { setupEventListeners };
};


