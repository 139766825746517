import { DistanceUnit, FormInputConfig, RequiredDistanceUnit } from "@types";


export const responseConfig: FormInputConfig = {
    name: 'response',
    label: 'Adjust server response latency',
    type: 'range',
    id: 'response'
}

export const animateConfig: FormInputConfig = {
    name: 'animate',
    label: 'Toggle trip line drawing animation',
    type: 'checkbox',
    role: 'switch',
    id: 'animate'
}

export const showBallTreeConfig: FormInputConfig = {
    name: 'showBallTree',
    label: 'Ball Tree Visualizer',
    type: 'checkbox',
    role: 'switch',
    id: 'showBallTree'
}

export const distanceUnitsConfig: FormInputConfig = {
    name: 'distanceUnit',
    label: 'Change the distances unit',
    type: 'select',
    id: 'distanceUnit'
}

export const distanceUnits: Record<string, DistanceUnit> = {
    miles: {
        name: "Miles",
        id: 'miles',
        abbreviation: "mi",
        conversionFactor: 1,
        earthRadius: 3959 // Radius of Earth in miles
    } as RequiredDistanceUnit,
    kilometers: {
        name: "Kilometers",
        id: 'kilometers',
        abbreviation: "km",
        conversionFactor: 1.60934,
        earthRadius: 6371 // Radius of Earth in kilometers
    } as RequiredDistanceUnit,
    meters: {
        name: "Meters",
        id: 'meters',
        abbreviation: "m",
        conversionFactor: 1609.34
    },
    yards: {
        name: "Yards",
        id: 'yards',
        abbreviation: "yd",
        conversionFactor: 1760
    }
};
