import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { isString } from "@craco/craco/dist/lib/utils";
import TripCardHeader from "@utils/TripCardHeader.tsx";
import { useDistances, useMenu, usePlaces, useStats } from '@state';

interface StatPairProps {
    title: string;
    value: number | string;
    unit?: string;
    useLocale?: boolean;
}

const Stats = () => {
    const { activeMenuItem, tripName, setTripName } = useMenu();
    const { leg, total } = useDistances();
    const { stats } = useStats();
    const { places } = usePlaces();

    const [longestLeg, setLongestLeg] = useState(0);
    const [shortestLeg, setShortestLeg] = useState(0);

    const headerContext = { tripName, setTripName };

    const StatsPair: React.FC<StatPairProps> = ({ title, value, unit, useLocale = false }) => {
        let displayValue;
        if (isString(value)) displayValue = value;
        else {
            if (value === 0) displayValue = '<1';
            else if (useLocale) {
                if (value >= 1e11) displayValue = value.toPrecision(3);
                else displayValue = value.toLocaleString('en-US');
            } else displayValue = value.toString();
        }
        return (
            <Row className="justify-content-between align-items-center stat-pair">
                <Col><h5>{title}:</h5></Col>
                <Col className="text-end">
                    <h5>{displayValue}{unit ? ` ${unit}` : ''}</h5>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        let maxLeg = 0;
        let minLeg = Number.MAX_SAFE_INTEGER;

        leg.forEach(dist => {
            if (dist > maxLeg) maxLeg = dist;
            if (dist < minLeg) minLeg = dist;
        });

        setLongestLeg(maxLeg);
        setShortestLeg(minLeg);
    }, [leg]);

    const calcAverageLeg = () => { return (total / leg.length).toFixed(1); }

    const BackendStats = () => {
        if (!stats) return;
        const { allToursIndex } = stats;
        const getFractionString = (i: number) => { return `${i}/${places.length}`}

        return (
            <>
                <StatsPair title={'Tours Progress'} value={getFractionString(allToursIndex)}/>
            </>
        );
    }

    const StatPairs = () => (
        <>
            <StatsPair title={'Tour Distance'} value={total} unit={'miles'} useLocale/>
            <StatsPair title={'Longest Leg'} value={longestLeg} unit={'miles'} useLocale/>
            <StatsPair title={'Shortest Leg'} value={shortestLeg} unit={'miles'} useLocale/>
            <StatsPair title={'Average Leg'} value={calcAverageLeg()} unit={'miles'} useLocale/>
            <BackendStats/>
        </>
    )

    return activeMenuItem === 'stats' && (
        <Card>
            <TripCardHeader {...headerContext} />
            <CardBody>
                <StatPairs/>
            </CardBody>
            <CardFooter>

            </CardFooter>
        </Card>
    )
}

export default Stats;
