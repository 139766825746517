import { create } from 'zustand';
import { Cartesian3, Math as CesiumMath, BoundingSphere, HeadingPitchRange } from 'cesium';
import { useViewer, useTripLines } from '@state';
import React from 'react';

interface ViewControllerState {
    isSpinning: boolean;
    startSpinning: () => void;
    stopSpinning: () => void;
    fitPositionsInView: (onComplete?: () => void) => void;
    fitGlobeInView: () => void;
    spinFunctionRef: React.MutableRefObject<(() => void) | undefined>;
}

export const useViewController = create<ViewControllerState>((set, get) => ({
    isSpinning: false,
    spinFunctionRef: { current: undefined },

    startSpinning: () => {
        const viewer = useViewer.getState().viewer;
        if (!viewer) return;

        if (get().isSpinning) return;
        set({ isSpinning: true });

        let angle = 0;
        const speed = CesiumMath.toRadians(0.1);
        const fixedHeading = CesiumMath.toRadians(0); // Maintain a fixed yaw

        const spinFunction = () => {
            angle += speed;
            if (angle > CesiumMath.TWO_PI) angle -= CesiumMath.TWO_PI;

            const latitude = 30 * Math.sin(angle); // Panning between -30° and +30°
            const altitude = calculateOptimalAltitude();

            viewer.camera.setView({
                destination: Cartesian3.fromDegrees(CesiumMath.toDegrees(angle), latitude, altitude),
                orientation: {
                    heading: fixedHeading, // Fixed yaw
                    pitch: CesiumMath.toRadians(-90), // Directly downwards
                    roll: 0
                }
            });
        };

        set({ spinFunctionRef: { current: spinFunction }});
        viewer.scene.postUpdate.addEventListener(spinFunction);
    },

    stopSpinning: () => {
        if (!get().isSpinning) return;
        set({ isSpinning: false });

        const viewer = useViewer.getState().viewer;
        const spinFunction = get().spinFunctionRef.current;
        if (viewer && spinFunction) {
            viewer.scene.postUpdate.removeEventListener(spinFunction);
        }
    },

    fitPositionsInView: (onComplete?: () => void) => {
        const viewer = useViewer.getState().viewer;
        const positions = useTripLines.getState().positions;

        if (!viewer || !positions || positions.length === 0) return;

        const boundingSphere = BoundingSphere.fromPoints(positions);
        if (!boundingSphere) return;

        viewer.camera.flyToBoundingSphere(boundingSphere, {
            duration: 0.5,
            offset: new HeadingPitchRange(0, CesiumMath.toRadians(-90), boundingSphere.radius * 3)
        });

        if (onComplete) onComplete();
    },



    fitGlobeInView: () => {
        const viewer = useViewer.getState().viewer;

        if (!viewer) return;

        const earthRadius = 6371000; // Meters
        const distanceMultiplier = calculateDistanceMultiplier();

        const earthBoundingSphere = new BoundingSphere(
            Cartesian3.ZERO,
            earthRadius
        );

        viewer.camera.flyToBoundingSphere(earthBoundingSphere, {
            duration: 1.0,
            offset: new HeadingPitchRange(
                0,
                CesiumMath.toRadians(-30),
                earthRadius * distanceMultiplier
            )
        })
    }


}));

const calculateDistanceMultiplier = () => {
    const aspectRatio = window.innerWidth / window.innerHeight;
    // Base multiplier adjusted for portrait orientation to ensure visibility is maintained
    const baseMultiplier = aspectRatio > 1 ? 5 : 4;
    // Adjust multiplier based on aspect ratio; more for portrait to ensure the globe fits vertically
    return baseMultiplier * Math.max(aspectRatio, 1 / aspectRatio);
};

const calculateOptimalAltitude = () => {
    const aspectRatio = window.innerWidth / window.innerHeight;
    const baseAltitude = 2e7; // Base altitude for a "square" aspect ratio
    const sigma = -3.33; // Reduced sensitivity to reduce dramatic changes at extreme aspect ratios
    const damping = 0.1; // Damping factor to limit maximum altitude adjustment

    // Calculate an adjustment factor that increases slowly as aspect ratio deviates from 1
    let adjustmentFactor;
    if (aspectRatio > 1) {
        // For landscape orientation, increase altitude as the screen gets wider
        adjustmentFactor = Math.exp(damping * (aspectRatio - 1) * sigma);
    } else {
        // For portrait orientation, decrease altitude as the screen gets taller
        adjustmentFactor = Math.exp(damping * (1 - aspectRatio) * sigma);
    }

    // Apply the adjustment factor to the base altitude
    return baseAltitude * adjustmentFactor;
};
