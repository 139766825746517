import { create } from 'zustand';
import { Place } from '@models/place.model.tsx';
import { Cartesian3 } from 'cesium';
import { Point } from '@models/point.model.tsx';
import { PlacesState, PolarPair } from '@types';

const GEOCODE_URL = "https://nominatim.openstreetmap.org/reverse?format=jsonv2";

const reverseGeocode = async (cartesian3: Cartesian3) => {
    const point = new Point(cartesian3);
    const { degrees } = point;

    const latLngToPlace = (degrees: PolarPair) => {
        return { latitude: degrees.latitude.toString(), longitude: degrees.longitude.toString() }
    }

    const API_URL = GEOCODE_URL + `&lat=${degrees.latitude}&lon=${degrees.longitude}`;

    try {
        const data = await fetch(API_URL);
        const dataJSON = await data.json();

        if (dataJSON.display_name) return new Place({ point: point, ...latLngToPlace(degrees), ...dataJSON.address });
        else {
            console.warn('No display_name found in API response. Returning "Unknown" place.');
            return new Place({ name: 'Unknown', point: point, ...latLngToPlace(degrees) });
        }
    } catch (error) {
        console.error('Error during reverse geocoding:', error);
        return;
    }
}

export const usePlaces = create<PlacesState>((set, get) => ({
    places: [],
    selectedIndex: -1,
    append: async (cartesian3) => {
        const fullPlace = await reverseGeocode(cartesian3);
        if (fullPlace) {
            set(state => ({
                places: [...state.places, fullPlace],
                selectedIndex: state.places.length
            }));
        } else {
            console.error('Failed to reverse geocode the provided Cartesian3 coordinates.');
        }
    },
    removeAtIndex: (index) => {
        const { places, selectedIndex } = get();
        if (index < 0 || index >= places.length) {
            console.error(`Attempted to remove index ${index} in places list of size ${places.length}.`);
            return;
        }
        const newPlaces = places.filter((_place, i) => i !== index);
        set({
            places: newPlaces,
            selectedIndex: index === selectedIndex ? -1 : selectedIndex > index ? selectedIndex - 1 : selectedIndex
        });
    },
    removeAll: () => {
        set({
            places: [],
            selectedIndex: -1
        });
    },
    selectIndex: (index) => {
        const { places } = get();
        if (index < -1 || index >= places.length) {
            console.error(`Attempted to select index ${index} in places list of size ${places.length}.`);
            set({ selectedIndex: -1 });
            return;
        }
        set({ selectedIndex: index });
    },
    setPlaces: (newPlaces) => {
        set({ places: newPlaces });
    }
}));
