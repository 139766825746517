import { create } from 'zustand';
import { Cartesian3, Color, PolylineGlowMaterialProperty, CallbackProperty } from 'cesium';
import { AnimationIntervals } from '@types';
import { usePlaces, useViewController, useViewer } from '@state';

export interface TripLinesState {
    pathData: Cartesian3[][];
    positions: Cartesian3[];
    animationIntervals: AnimationIntervals;
}

export interface TripLinesActions {
    computeTrip: (onComplete?: () => void) => void;
    constructLines: (onComplete?: () => void) => void;
    constructAnimated: (onComplete?: () => void) => void;
    setPathData: (pathData: Cartesian3[][]) => void;
    setPositions: (positions: Cartesian3[]) => void;
    setAnimationIntervals: (animationIntervals: AnimationIntervals) => void;
    initializeTripLines: (pathData: Cartesian3[][], positions: Cartesian3[], animationIntervals: AnimationIntervals) => void;
    allPointsAreVisible: () => boolean;
    quadrantAnimate: (onComplete?: () => void) => void;
    constructPoints: (onComplete?: () => void) => void;
}

export const useTripLines = create<TripLinesState & TripLinesActions>((set, get) => ({
    pathData: [],
    positions: [],
    animationIntervals: [],

    setPathData: (pathData) => set({ pathData, positions: pathData.flatMap(segment => segment) }),
    setPositions: (positions) => set({ positions }),
    setAnimationIntervals: (animationIntervals) => set({ animationIntervals }),
    initializeTripLines: (pathData, positions, animationIntervals) => set({ pathData, positions, animationIntervals }),

    computeTrip: (onComplete) => {
        const places = usePlaces.getState().places;
        if (places.length < 2) {
            set({ pathData: [] });
            onComplete?.();
            return;
        }
        const segments: Cartesian3[][] = [];
        const antipodalThreshold = Math.PI - 0.0125;
        places.forEach((place, i) => {
            const current = place.point.cartesian3;
            const next = places[(i + 1) % places.length].point.cartesian3;
            const angleBetween = Cartesian3.angleBetween(current, next);
            if (angleBetween > 0.0125 && Cartesian3.distance(current, next) < 1e7) {
                if (angleBetween < antipodalThreshold) {
                    segments.push([current, next]);
                } else {
                    const midpoint = Cartesian3.midpoint(current, next, new Cartesian3());
                    segments.push([current, midpoint], [midpoint, next]);
                }
            }
        });
        set({ pathData: segments, positions: segments.flatMap(segment => segment) });
        onComplete?.();
    },

    constructLines: (onComplete) => {
        const viewer = useViewer.getState().viewer;
        if (!viewer) return;

        const pathData = get().pathData;
        viewer.entities.add({
            polyline: {
                positions: pathData.flatMap(segment => segment),
                width: 7,
                material: new PolylineGlowMaterialProperty({
                    color: Color.DEEPSKYBLUE,
                    glowPower: 0.25
                })
            }
        });
        onComplete?.();
    },

    constructAnimated: (onComplete) => {
        const viewer = useViewer.getState().viewer;
        if (!viewer) return;

        const { pathData, animationIntervals } = get();
        const startTime = Date.now();
        const totalAnimationDuration = 2000;
        pathData.forEach((segment, index) => {
            viewer.entities.add({
                polyline: {
                    positions: new CallbackProperty(() => {
                        const elapsed = Date.now() - startTime;
                        const interval = animationIntervals[index] || { start: index * totalAnimationDuration / pathData.length, end: (index + 1) * totalAnimationDuration / pathData.length };
                        if (elapsed > interval.end) return segment;
                        else if (elapsed > interval.start) {
                            const segmentProgress = (elapsed - interval.start) / (interval.end - interval.start);
                            const midPoint = Cartesian3.lerp(segment[0], segment[1], segmentProgress, new Cartesian3());
                            return [segment[0], midPoint];
                        } else return [segment[0]];
                    }, false),
                    width: 7,
                    material: new PolylineGlowMaterialProperty({
                        color: Color.DEEPSKYBLUE,
                        glowPower: 0.25
                    })
                }
            });
        });
        let frameRef = requestAnimationFrame(function animate() {
            if (Date.now() - startTime > totalAnimationDuration + 100) {
                cancelAnimationFrame(frameRef);
                onComplete?.();
            } else {
                frameRef = requestAnimationFrame(animate);
            }
        });
        return () => {
            cancelAnimationFrame(frameRef);
        };
    },

    quadrantAnimate: (onComplete) => {
        const viewer = useViewer.getState().viewer;
        const stopSpinning = useViewController.getState().stopSpinning;
        if (!viewer) {
            console.error("Viewer not available");
            return;
        }

        let animationCount = 0;
        const totalQuadrants = 4;  // Define total number of quadrants to animate through

        const animatePath = () => {
            stopSpinning();
            console.log(`Animating quadrant: ${animationCount}`);
            if (animationCount >= totalQuadrants) {
                console.log("All quadrant animations completed.");
                onComplete?.();
                return;
            }

            // Randomly select a latitude and choose a specific longitude from predefined quadrants
            const latitude = Math.random() > 0.5 ? 20 : -20;
            const longitudes = [30, -70, 90, -120];  // Major landmass longitudes
            const longitude = longitudes[animationCount];  // Sequentially select longitude for each quadrant

            viewer.camera.flyTo({
                destination: Cartesian3.fromDegrees(longitude, latitude, 10000),
                complete: () => {
                    console.log(`Camera positioned for quadrant ${animationCount}`);

                    // Remove previous entities and invoke constructAnimated to draw animated paths for the current quadrant
                    viewer.entities.removeAll();
                    get().constructAnimated(() => {
                        console.log(`Animation complete for quadrant ${animationCount}`);
                        animationCount++;
                        setTimeout(animatePath, 2000);  // Delay to allow users to view the animation before proceeding
                    });
                }
            });
        };

        animatePath();  // Start the animation sequence
    },

    allPointsAreVisible: () => {
        const positions = get().positions;
        if (!positions) return true;
        const maxDist = 1e7;
        const p = positions[0];
        for (let i = 1; i < positions.length; ++i) {
            if (Cartesian3.distance(p, positions[i]) > maxDist) return false;
        }
        return true;
    },

    constructPoints: (onComplete) => {
        const viewer = useViewer.getState().viewer;
        const places = usePlaces.getState().places;
        for (const place of places) {
            viewer?.entities.add({
                position: place.point.cartesian3,
                point: {
                    pixelSize: 3,
                    color: Color.DEEPSKYBLUE,
                    outlineColor: Color.AQUAMARINE,
                }
            })
        }
        onComplete?.();
    }
}));
