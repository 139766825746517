import { useEffect } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars, faFloppyDisk, faFolderOpen, faGaugeHigh, faGear, faMapLocationDot, faRoute, faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import MenuButton from '@components/Menu/MenuButton.tsx';
import { useMenu, usePlaces, useStats } from '@state';
import { ActiveMenuItem } from '@types';


const Menu = () => {
    const { resetStats } = useStats();
    const { tripName, setDisableSave, disableSave, setScreenWidth, screenWidth, menuOpen, toggleMenu, setActiveMenuItem } = useMenu();
    const { places, removeAll: removeAllPlaces } = usePlaces();

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const removeAll = () => {
        removeAllPlaces();
        setDisableSave(false);
        resetStats();
    };

    const saveTrip = () => {
        const file = new Blob([JSON.stringify({ places: places })], { type: "application/json" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(file);
        link.href = url;
        link.download = tripName + ".json";
        document.body.appendChild(link);
        link.click();

        setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 0);
    };

    const menuButtons = [
        { key: "settings", icon: faGear, text: "Settings" },
        { key: "itinerary", icon: faMapLocationDot, text: "Itinerary" },
        { key: "stats", icon: faGaugeHigh, text: "Stats" },
        { key: "demo", icon: faRoute, text: "Demo Files" },
        { key: "load", icon: faFolderOpen, text: "Load Trip" },
        { key: "save", icon: faFloppyDisk, text: "Save Trip", action: saveTrip, disabled: disableSave },
        { key: "remove", icon: faTrashCan, text: "Remove All", action: removeAll, disabled: !places?.length }
    ].map(({ key, icon, text, action, disabled }) => (
        <MenuButton
            key={key}
            itemKey={key as ActiveMenuItem}
            dataTestId={`${key}-button`}
            buttonIcon={<FontAwesomeIcon icon={icon}/>}
            buttonText={text}
            buttonAction={action}
            disabled={disabled}
            isDropdown={screenWidth < 800}
        />
    ));

    const menuToggleMaster = () => {
        if (!menuOpen) setActiveMenuItem(null);
        toggleMenu();
    }

    const DynamicMenu = () => {
        return screenWidth < 800 ? (
            <Dropdown isOpen={menuOpen} toggle={menuToggleMaster}>
                <DropdownToggle className="menu-toggle" color="primary" data-testid="menu-toggle">
                    <FontAwesomeIcon icon={faBars}/>
                </DropdownToggle>
                <DropdownMenu data-testid="menu-button-container" end>
                    {menuButtons}
                </DropdownMenu>
            </Dropdown>
        ) : (
            <div className={'menu-no-collapse'}>
                {menuButtons}
            </div>
        );
    }

    return (
        <div className='dynamic-menu'>
            <DynamicMenu />
        </div>
    )
}

export default Menu;

