import { SnackbarProvider } from 'notistack';
import Page from './components/Page';

export default function App(): JSX.Element {
    return (
        <SnackbarProvider maxSnack={3} preventDuplicate>
            <Page />
        </SnackbarProvider>
    );
}
