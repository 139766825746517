import { create } from 'zustand';
import { useEffect } from 'react';
import { MenuState } from '@types';


export const useMenu = create<MenuState>((set) => ({
    activeMenuItem: null,
    setActiveMenuItem: (menuItem) => set((state) => ({
        activeMenuItem: state.activeMenuItem === menuItem ? null : menuItem
    })),
    tripName: 'My Trip',
    setTripName: (name) => set({ tripName: name }),
    disableSave: false,
    setDisableSave: (disable) => set({ disableSave: disable }),
    menuOpen: false,
    toggleMenu: () => set((state) => ({ menuOpen: !state.menuOpen })),
    screenWidth: window.innerWidth,
    setScreenWidth: (width) => set({ screenWidth: width })
}));

// Utility to handle screen resize
export function useScreenSize() {
    const setScreenWidth = useMenu(state => state.setScreenWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [setScreenWidth]);
}
