import { create } from 'zustand';
import { distanceUnits } from '@utils/settingsConfig.ts';
import { ConfigStore, DistanceUnit, RequiredDistanceUnit } from '@types';

export const useConfig = create<ConfigStore>((set) => ({
    settings: {
        tourId: "",
        response: 1.0,
        animate: true,
        showBallTree: false,
        distanceUnit: distanceUnits.miles as RequiredDistanceUnit
    },
    updateSetting: (key, value) => set(state => ({
        settings: { ...state.settings, [key]: value }
    })),
    setTourId: (value) => set(state => ({ settings: { ...state.settings, tourId: value } })),
    setResponse: (value) => set(state => ({ settings: { ...state.settings, response: value } })),
    setAnimated: (value) => set(state => ({ settings: { ...state.settings, animate: value } })),
    setShowBallTree: (value) => set(state => ({ settings: { ...state.settings, showBallTree: value } })),
    setDistanceUnit: (value) => {
        const unit: DistanceUnit = distanceUnits[value];
        if (!unit.earthRadius) unit.earthRadius = EARTH_RADIUS_UNITS_DEFAULT.miles * unit.conversionFactor;
        set(state => ({ settings: { ...state.settings, distanceUnit: unit as RequiredDistanceUnit } }));
    },
    clearTourId: () => set(state => ({ settings: { ...state.settings, tourId: undefined } })),
}));
