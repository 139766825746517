import { Card, CardBody, CardFooter, Container } from "reactstrap";
import TripCardHeader from "@utils/TripCardHeader.tsx";
import { Place } from "@models/place.model.tsx";
import PlaceRow from "@utils/PlaceRow.tsx";
import React from "react";
import { useDistances, useMenu, usePlaces } from '@state';

const Itinerary = () => {
    const { places , setPlaces, append, removeAll, removeAtIndex, selectIndex, selectedIndex } = usePlaces();
    const { activeMenuItem, tripName, setTripName } = useMenu();
    const distances = useDistances();

    const placeActions = { setPlaces, append, removeAll, removeAtIndex, selectIndex }

    const headerContext = { tripName, setTripName };

    const buildPlaces = () => {
        return places.map((place: Place, index: number) => {
            const props = { place, index, placeActions, distances, selectedIndex };
            return <PlaceRow key={`table-${JSON.stringify(place)}-${index}`} {...props} />;
        });
    }

    return activeMenuItem === 'itinerary' && (
        <Card className={'itinerary-card'}>
            <TripCardHeader {...headerContext} />
            <CardBody>
                <Container className={'itinerary-container'}>
                    <table className="table">
                        <DistancesHeader/>
                        <tbody>{buildPlaces()}</tbody>
                    </table>
                </Container>
            </CardBody>
            <CardFooter>
            </CardFooter>
        </Card>
    );
}

const DistancesHeader: React.FC = () => (
    <thead>
    <tr>
        <td data-testid="leg-header" className="text-end">{"leg"}</td>
        <td data-testid="cumulative-header" className="text-end">{"Σ"}</td>
        <td></td>
    </tr>
    </thead>
);

export default Itinerary;
