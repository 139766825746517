import { useEffect } from 'react';
import { useViewer, useViewController, usePlaces, useTripLines, useConfig } from '@state';
import { useEventListeners, useRequests } from '@hooks';
import { useBallTreeViewer } from '~/state/useBallTreeViewer.tsx';

function useInitializeViewer() {
    const { viewer, initializeViewer } = useViewer();
    const { setupEventListeners } = useEventListeners();

    useEffect(() => {
        initializeViewer("cesiumContainer");
    }, [initializeViewer]);

    useEffect(() => {
        if (viewer) {
            return setupEventListeners();
        }
    }, [viewer, setupEventListeners]);
}

function useHandlePlaces() {
    const { viewer } = useViewer();
    const { places } = usePlaces();
    const { computeTrip, constructLines, constructAnimated, allPointsAreVisible, quadrantAnimate, constructPoints } = useTripLines();
    const { fitGlobeInView, stopSpinning, startSpinning, fitPositionsInView,  } = useViewController();
    const { settings: { animate, showBallTree } } = useConfig();
    const { makeDistancesRequest, makeTripLinesRequest } = useRequests();
    const { drawBallTree, makeBallTreeRequest, fitBallTreeInView, checkTreeFitInView } = useBallTreeViewer();

    useEffect(() => {
        if (!viewer) {
            console.error('Viewer is not available');
            return;
        }

        viewer.entities.removeAll();

        const updateVisualization = (onCompleted?: () => void) => {
            fitPositionsInView(() => {
                if (animate) {
                    if (places.length > 200) constructAnimated(() => { // constructAnimated(() => {
                        viewer.entities.removeAll();
                        constructLines();
                    })
                    else constructAnimated();
                }
                else constructLines();

                if (!allPointsAreVisible()) startSpinning();
                onCompleted?.();
            });
        };

        if (places.length > 1) {
            stopSpinning();
            if (showBallTree) {
                makeBallTreeRequest(() => {
                    constructPoints();
                    drawBallTree();
                    fitBallTreeInView(() => {
                        if (!checkTreeFitInView) startSpinning();
                    });
                })
            } else {
                if (places.length < 100) computeTrip(() => updateVisualization(makeDistancesRequest));
                else makeTripLinesRequest(updateVisualization);
            }
        } else {
            stopSpinning();
            fitGlobeInView();
        }
    }, [viewer, places, fitPositionsInView, fitBallTreeInView, animate, constructLines, allPointsAreVisible, startSpinning, quadrantAnimate, constructAnimated, stopSpinning, computeTrip, makeTripLinesRequest, makeDistancesRequest, fitGlobeInView, showBallTree]);
}

const CesiumController = () => {
    useInitializeViewer();
    useHandlePlaces();

    return <div id="cesiumContainer" style={{ width: '100%', height: '100%' }} />;
};

export default CesiumController;
