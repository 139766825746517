import { useController, useFormContext } from "react-hook-form";
import { FormInputConfig, InputControllerReturn } from "@types";
import React, { useEffect, useRef } from "react";
import { useConfig } from '@state';

const useInputController = (config: FormInputConfig): InputControllerReturn => {
    const { control, trigger, setValue } = useFormContext();
    const { name, id, type, placeholder, role, label } = config;
    const { field, fieldState: { error } } = useController({ name, control });
    const { onChange, value } = field;
    const { updateSetting, setDistanceUnit } = useConfig();

    const latestValue = useRef(value);
    useEffect(() => {
        latestValue.current = value;
    }, [value]);

    const debounceValidateAndUpdate = async () => {
        const isValidField = await trigger(name);
        if (isValidField) {
            let formattedValue = latestValue.current;
            if (type === 'float' && typeof formattedValue === 'number') formattedValue = formattedValue.toFixed(2);
            if (name === 'distanceUnit') setDistanceUnit(formattedValue);
            else updateSetting(name, formattedValue);
        }
    };

    const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue: string | boolean | number = event.target.value;
        if (type === 'checkbox') newValue = event.target.checked;
        else if (['number', 'range'].includes(type)) newValue = parseFloat(newValue);

        latestValue.current = newValue;
        onChange(event);
        debounceValidateAndUpdate();
    };

    const className = type === 'checkbox' ? 'form-check-input' : '';

    return {
        config,
        field: {
            ...field,
            id,
            className,
            placeholder,
            type,
            onChange: handleChangeEvent,
            role,
            label,
            valid: !error, // Dynamically assign validity based on the absence of errors.
            invalid: !!error
        },
        fieldState: { error },
        setValue,
        handleChange: handleChangeEvent
    };
};

export default useInputController;
