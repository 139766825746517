import { useConfig, useDistances, usePlaces, useTripLines, useWebSocket } from '@state';
import { Cartesian3 } from 'cesium';
import { useCallback } from 'react';
import { DistancesResponse, IResponse, TripLinesResponse } from '@types';


export const useRequests = () => {
    const { updateDistances } = useDistances();
    const { places } = usePlaces();
    const { sendData } = useWebSocket();
    const { settings: { distanceUnit: { earthRadius } } } = useConfig();
    const { setPathData, setAnimationIntervals } = useTripLines();

    const makeTripLinesRequest = useCallback(async (onCompleted?: () => void) => {
        sendData({ type: "tripLines", earthRadius: earthRadius, requestAnimation: true, durationInSeconds: 2.0, places }, (rawResponse: IResponse) => {
            if (rawResponse && isTripLinesResponse(rawResponse)) {
                setPathData(rawResponse.tripLines);
                updateDistances(rawResponse.distances);
                if (rawResponse.animationIntervals) setAnimationIntervals(rawResponse.animationIntervals);
                onCompleted?.();
            }
        })
    }, [places]);

    const makeDistancesRequest = useCallback(async (onCompleted?: () => void) => {
        sendData({ type: "distances", places, earthRadius }, (response: IResponse) => {
            if (isDistancesResponse(response)) {
                updateDistances(response.distances);
            } else {
                console.error('Invalid or undefined response:', response);
            }
        });
        onCompleted?.();
    }, [places]);

    return { makeDistancesRequest, makeTripLinesRequest };
}


function isDistancesResponse(response: IResponse): response is DistancesResponse {
    return response.type === "distances" &&
        'distances' in response &&
        Array.isArray(response.distances) &&
        response.distances.every(item => typeof item === 'number');
}

function isCartesian3Array(value: Cartesian3[] | unknown): value is Cartesian3[] {
    return Array.isArray(value) && value.every(point =>
        typeof point.x === 'number' &&
        typeof point.y === 'number' &&
        typeof point.z === 'number'
    );
}

function isTripLinesResponse(response: IResponse): response is TripLinesResponse {
    return response.type === 'tripLines' && Array.isArray(response.tripLines)
        && response.tripLines.every((item): item is Cartesian3[] => isCartesian3Array(item));
}
