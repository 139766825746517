import { create } from 'zustand';
import Ajv from 'ajv';
import tripFileSchema from '@static/schemas/TripFile.json';
import { FileStore } from '@types';

export const useFiles = create<FileStore>((set, get) => ({
    fileValidity: false,
    filename: "",
    disallowFileLoad: false,
    showValidityIcon: false,
    loadedPlaces: undefined,

    setFileValidity: (value) => set({ fileValidity: value }),
    setFileName: (value) => set({ filename: value }),
    setDisallowFileLoad: (value) => set({ disallowFileLoad: value }),
    setShowValidityIcon: (value) => set({ showValidityIcon: value }),
    setLoadedPlaces: (value) => set({ loadedPlaces: value }),

    loadPlaces: async (tripString) => {
        if (isValidJsonFile(tripString)) {
            try {
                const tripObject = JSON.parse(tripString);
                get().setLoadedPlaces(tripObject.places);
                get().setFileValidity(true);
            } catch (error) {
                console.error("Error loading JSON file:", error);
                displayErrorMessage("Error processing the trip file.");
            }
        } else {
            displayErrorMessage("Invalid JSON file format.");
        }
    },
    clearLoadedPlaces: () => set({ loadedPlaces: undefined }),
}));

const isValidJsonFile = (tripString: string) => {
    try {
        const ajv = new Ajv({ allErrors: true });
        const validate = ajv.compile(tripFileSchema);
        const tripObject = JSON.parse(tripString);
        const valid = validate(tripObject);
        if (!valid) {
            console.error('JSON validation error:', validate.errors);
            return false;
        }
        return true;
    } catch (error) {
        console.error("Failed to parse JSON:", error);
        return false;
    }
};

const displayErrorMessage = (message: string) => {
    const { setDisallowFileLoad, setShowValidityIcon, setFileValidity } = useFiles.getState();
    console.error("Load Error:", message);
    setDisallowFileLoad(true);
    setShowValidityIcon(true);
    setFileValidity(false);
};
