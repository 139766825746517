import { PlaceObj } from '@types';
import { Point } from '@models/point.model.tsx';

export class Place {
    name: string;
    streetAddress: string;
    municipality: string;
    region: string;
    country: string;
    postcode: string;
    defaultDisplayName: string;
    point: Point;

    constructor(placeObj: PlaceObj) {
        this.name = placeObj.name ?? placeObj.leisure ?? placeObj.building ?? placeObj.amenity ?? placeObj.man_made ?? '';
        this.streetAddress = this.buildStreetAddress(placeObj);
        this.municipality = placeObj.municipality ?? placeObj.city ?? placeObj.village ?? placeObj.state_district ?? placeObj.town ?? placeObj.county ?? '';
        this.region = placeObj.region ?? placeObj.state ?? '';
        this.country = placeObj.country ?? '';
        this.postcode = placeObj.postcode ?? '';
        this.defaultDisplayName = this.buildDefaultDisplayName();
        this.point = new Point(placeObj.point);
    }

    public coordinatesToString(precision: number = 2): string {
        const lat = this.point.degrees.latitude;
        const lng = this.point.degrees.longitude;

        const formatLat = (latitude: number): string => latitude >= 0 ? 'N' : 'S';
        const formatLng = (longitude: number): string => longitude >= 0 ? 'E' : 'W';

        return `${lat.toFixed(precision)}°${formatLat(lat)}, ${lng.toFixed(precision)}°${formatLng(lng)}`;
    }

    private formatPlace(): string {
        return `${this.hasName()}${this.hasStreetAddress()}${this.hasMunicipality()}${this.hasRegion()}${this.hasCountry()}${this.hasPostcode()}`;
    }

    private buildStreetAddress(placeObj: PlaceObj): string {
        return `${this.hasHouseNumber(placeObj)}${this.hasRoad(placeObj)}${this.hasSuburb(placeObj)}`;
    }

    private buildDefaultDisplayName(): string {
        return this.formatPlace().split(',')[0];
    }

    private hasName(): string {
        return this.placeHasProps(this.name, []);
    }

    private hasStreetAddress(): string {
        return this.placeHasProps(this.streetAddress, [this.hasName()]);
    }

    private hasMunicipality(): string {
        return this.placeHasProps(this.municipality, [this.hasName(), this.hasStreetAddress()]);
    }

    private hasRegion(): string {
        return this.placeHasProps(this.region, [this.hasName(), this.hasStreetAddress(), this.hasMunicipality()]);
    }

    private hasCountry(): string {
        return this.placeHasProps(this.country, [this.hasName(), this.hasStreetAddress(), this.hasMunicipality(), this.hasRegion()]);
    }

    private hasPostcode(): string {
        return this.placeHasProps(this.postcode, [this.hasName(), this.hasStreetAddress(), this.hasMunicipality(), this.hasRegion(), this.hasCountry()]);
    }

    private hasHouseNumber(placeObj: PlaceObj): string {
        return placeObj.house_number ?? '';
    }

    private hasRoad(placeObj: PlaceObj): string {
        return placeObj.road ? `${this.hasHouseNumber(placeObj) ? ' ' : ''}${placeObj.road}` : '';
    }

    private hasSuburb(placeObj: PlaceObj): string {
        return this.placeHasProps(placeObj.suburb ?? '', [this.hasHouseNumber(placeObj), this.hasRoad(placeObj)]);
    }

    private placeHasProps(placeProp: string, precedingProps: string[]): string {
        return placeProp && precedingProps.some(Boolean) ? `, ${placeProp}` : placeProp;
    }
}
