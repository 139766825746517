import React from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useFiles, useMenu, usePlaces } from '@state';
import { Place } from '@models/place.model.tsx';

const LoadFile = () => {
    const { setPlaces } = usePlaces();
    const { activeMenuItem, setActiveMenuItem, setTripName } = useMenu();
    const { loadPlaces, setFileName, setDisallowFileLoad, disallowFileLoad, filename, setLoadedPlaces, setShowValidityIcon, showValidityIcon, loadedPlaces, fileValidity } = useFiles();

    const clear = () => {
        setActiveMenuItem(null);
        setShowValidityIcon(false);
        setDisallowFileLoad(true);
    }
    

    const ValidityMessage: React.FC<{ fileValidity: boolean }> = ({ fileValidity }) => (
        <span data-testid="load-validity-message">
            {fileValidity ? (<span><FaCheck color="green"/>Confirm Below</span>) : (<span><FaTimes color="red"/>Invalid File</span>)}
        </span>
    );

    const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => { // Define and replace `any` with proper types
        const reader = new FileReader();
        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            setFileName(file.name);

            reader.onload = async (event: ProgressEvent<FileReader>) => {
                setDisallowFileLoad(true);
                const tripString = event.target?.result;
                if (typeof tripString === 'string') await loadPlaces(tripString);
            };
            reader.readAsText(file);
        }
    };

    const ConfirmLoadButton = () => (
        <Button color="primary"
                disabled={disallowFileLoad}
                onClick={() => {
                    setPlaces(loadedPlaces as Place[]);
                    clear();
                    setLoadedPlaces([]);
                    setTripName(RemoveFileExtension(filename));
                }}
                data-testid="confirm-load-button">
            Load Selected File
        </Button>
    );

    const RemoveFileExtension = (fileName: string) => {
        const finalDotIndex = fileName.lastIndexOf('.');
        return finalDotIndex === -1 ? fileName : fileName.substring(0, finalDotIndex);
    };

    const CancelLoadButton = () => (
        <Button color="secondary"
                onClick={() => {
                    setLoadedPlaces([]);
                    clear();
                }}
                data-testid="close-load">
            Cancel
        </Button>
    )

    return activeMenuItem === 'load' && (
        <Card>
            <CardHeader>
                <CardTitle>Load a Trip</CardTitle>
            </CardHeader>
            <div>
                <CardBody>
                    <input type="file" onChange={(e) => onUpload(e)} data-testid="input-file-button"/>
                            {showValidityIcon && <ValidityMessage fileValidity={fileValidity}/>}
                </CardBody>
                <CardFooter>
                    <Row>
                        <Col md={6}><CancelLoadButton /></Col>
                        <Col md={6}><ConfirmLoadButton /></Col>
                    </Row>
                </CardFooter>
            </div>
        </Card>
    );
};


export default LoadFile;
