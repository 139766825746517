import { create } from 'zustand';
import { Viewer } from 'cesium';

interface ViewerState {
    viewer: Viewer | null;
    initializeViewer: (container: HTMLDivElement | string, viewerOptions?: any) => void;
    destroyViewer: () => void;
}

export const useViewer = create<ViewerState>((set, get) => ({
    viewer: null,

    initializeViewer: (container, viewerOptions = {}) => {
        if (get().viewer) {
            console.warn("Viewer is already initialized.");
            return;
        }

        const newViewer = new Viewer(container, {
            ...viewerOptions,
            // Additional default options can be specified here.
        });

        set({ viewer: newViewer });
    },

    destroyViewer: () => {
        get().viewer?.destroy();
        set({ viewer: null });
    }
}));
