import React from "react";
import { DropdownItem } from "reactstrap";
import { useMenu } from '@state';
import { MenuButtonProps } from '@types';

const MenuButton: React.FC<MenuButtonProps> = (props) => {
    const {
        itemKey,
        dataTestId,
        buttonAction,
        buttonIcon,
        buttonText,
        disabled = false,
        isDropdown = false
    } = props

    const { activeMenuItem, setActiveMenuItem } = useMenu();
    const defaultAction = () => setActiveMenuItem(activeMenuItem === itemKey ? null : itemKey);

    const getClass = () => {
        let str = 'menu-item ';
        if (activeMenuItem === itemKey) str += 'active';
        else if (disabled) str += 'disabled';
        return str;
    }

    return isDropdown ? (
        <DropdownItem
            key={itemKey}
            data-testid={dataTestId}
            disabled={disabled}
            onClick={buttonAction || defaultAction}
        >
            <div className={getClass()}>
                {buttonIcon}
                &nbsp;&nbsp;{isDropdown && buttonText}
            </div>
        </DropdownItem>
    ) : (
        <div
            key={itemKey}
            data-testid={dataTestId}
            onClick={buttonAction || defaultAction}
            className={getClass()}
        >
            {buttonIcon}
            &nbsp;&nbsp;{isDropdown && buttonText}
        </div>
    )
}


export default MenuButton;
