import { create } from 'zustand';
import { DistancesState } from '@types';

export const useDistances = create<DistancesState>((set) => {
    const calcCumulative = (distances: number[]): number[] => {
        let sum = 0;
        return distances.map(n => sum += n);
    }

    const calcTotal = (distances: number[]): number => {
        return distances.reduce((acc, cur) => acc + cur, 0);
    }

    return {
        leg: [],
        cumulative: [],
        total: 0,
        updateDistances: (distances: number[]) => {
            set({
                leg: distances,
                cumulative: calcCumulative(distances),
                total: calcTotal(distances)
            });
        }
    };
});
