// Demo.tsx
import React, { useEffect } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col, Row } from 'reactstrap';
import { useApp, useFiles, useMenu, usePlaces, useWebSocket } from '@state';
import { AllDemosResponse, DemoInfo, DemoResponse, IResponse } from '@types';

const Demo = () => {
    const { demos, setDemos, loadedDemos, setLoadedDemos } = useApp();
    const { sendData } = useWebSocket();
    const { disallowFileLoad, loadPlaces, loadedPlaces, clearLoadedPlaces } = useFiles();
    const { setPlaces } = usePlaces();
    const { activeMenuItem, setActiveMenuItem, setDisableSave, setTripName } = useMenu();

    function isAllDemosResponse(response: IResponse): response is AllDemosResponse {
        return response.type === 'allDemos' && Array.isArray(response.demos);
    }

    const isDemoResponse = (response: IResponse): response is DemoResponse => {
        return response.type === 'demo' && 'places' in response;
    }

    useEffect(() => {
        if (!loadedDemos) {
            const fetchDemos = async () => {
                sendData({ type: 'allDemos' }, (response: IResponse) => {
                    if (isAllDemosResponse(response)) setDemos(response.demos);
                    else console.error("Invalid response structure:", response);
                });
            };
            fetchDemos();
            setLoadedDemos(true);
        }
    }, [loadedDemos, sendData, setDemos, setLoadedDemos]);

    useEffect(() => {
        if (loadedPlaces && loadedPlaces.length > 0) {
            setPlaces(loadedPlaces);
            clearLoadedPlaces();
        }
    }, [loadedPlaces]);

    const handleDemoSelection = async (demo: DemoInfo) => {
        sendData({ type: 'demo', name: demo.name }, (response: IResponse) => {
            if (isDemoResponse(response)) {
                loadPlaces(JSON.stringify(response));
                setDisableSave(true);
                setTripName(demo.name);
                setActiveMenuItem(null);
            }
        });
    };

    const DemoButton = React.memo((demo: DemoInfo) => {
        const { name, size } = demo;
        return (
            <Row className="my-2 justify-content-center">
                <Col sm={6}>
                    <Button
                        onClick={() => handleDemoSelection(demo)}
                        className="w-100"
                        disabled={disallowFileLoad}
                    >
                        <div className="text-left">{name}</div>
                        <div className="text-right">Size: {size}</div>
                    </Button>
                </Col>
            </Row>
        );
    });

    const DemoButtons = () => {
        return demos.map((demo, index) => <DemoButton key={`demo-${index}`} {...demo} />);
    }

    return activeMenuItem === 'demo' && (
        <Card>
            <CardHeader>
                <CardTitle>Select Demo</CardTitle>
            </CardHeader>
            <CardBody>
                <CardText>
                    <span className={'demo-warning'}>
                        Enabling demo mode will remove the ability to save tour until a reset occurs
                    </span>
                </CardText>
                <DemoButtons />
            </CardBody>
            <CardFooter>
                <Button color="secondary" onClick={() => setActiveMenuItem(null)}>
                    Close
                </Button>
            </CardFooter>
        </Card>
    );
};

export default Demo;
