import React, { useState } from "react";
import { Button, CardHeader, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { ItineraryHeaderProps } from "@types";


const TripCardHeader: React.FC<ItineraryHeaderProps> = ({ tripName, setTripName }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editableName, setEditableName] = useState(tripName);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditableName(event.target.value);
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = () => {
        setTripName(editableName);
        setIsEditing(false);

        document.body.focus();
    };

    const TripIdentifier = () => {
        return isEditing ? (
            <>
                <input type="text" value={editableName} onChange={handleNameChange} autoFocus/>
                <Button onClick={handleSave} color="primary" size="sm" style={{ marginLeft: '8px' }}>Save</Button>
            </>
        ) : (
            <h2>
                {editableName}
                <span className={'fa-edit-trip-name'}>
                        <FontAwesomeIcon icon={faPenToSquare} onClick={handleEditToggle}/>
                    </span>
            </h2>
        );
    }

    return (
        <CardHeader>
            <CardTitle>
                <TripIdentifier/>
            </CardTitle>
        </CardHeader>
    )
}

export default TripCardHeader;
